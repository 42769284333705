import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';

import { mixin, color } from '@src/settings';

import Hero from '@components/common/hero';
import PageIntro from '@components/common/page-intro';
import ContactIntro from '@components/common/contact-intro';

import Quality_Hero__mds from '@img/svg/quality/hero__mds.svg';

import Column from '@components/quality/column';
import { PageCont, Title, Container } from '@components/quality/styles';

const QualityPage = ({ data }) => (
	<PageCont baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Quality_hero_img"] }
			title={<Quality_Hero__mds />}
			subTitle="品質・環境への取り組み"
		/>
		<PageIntro
			title={<>地球にも人にも快適な環境づくり</>}
			subtitle={<>当社では、地球にも人にも快適な環境づくりが企業の発展にといて最重要課題であることを認識し、また、その取り組みが高品質なサービス提供につながっていくと考え、ISO等の規格認証を取得しました。今後も、規格に沿った企業活動を展開し、より一層のサービス工場を目指していきます。</>}
		 />
		<Container>

			<Title>ISO ACHIEVEMENTS</Title>

			<Column className="Column"
				img={ data["Iso14001"] }
				title="Certificate ISO 14001"
				cap="2013年1月に品質マネジメントの国際規格であるISO14001の認証を取得いたしました。"
				data={[
					{ key:"適合規格", val:"ISO14001:2004"},
					{ key:"登録番号", val:"413127E"},
					{ key:"登録事業者", val:"LSF Technology Kajang 工場"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iso14001_2015"] }
				title="Certificate ISO 14001"
				cap="2013年1月に品質マネジメントの国際規格であるISO14001の認証を取得いたしました。"
				data={[
					{ key:"適合規格", val:"ISO14001:2020"},
					{ key:"登録番号", val:"647739-M"},
					{ key:"登録事業者", val:"LSF Technology Kajang 工場"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iso9001"] }
				title="Certificate ISO 9001"
				cap="2004年1月に環境マネジメントの国際規格であるISO9001の認証を取得いたしました。"
				data={[
					{ key:"適合規格", val:"ISO9001:2015"},
					{ key:"登録番号", val:"16146-A"},
					{ key:"登録事業者", val:"LSF Technology Kajang 工場"}
				]}
				isIso
			/>

			<Column className="Column"
				img={ data["Iatf16146"] }
				title="Certificate IATF 16949"
				cap="2017年4月に自動車産業品質マネジメントの国際規格であるIATF 16949の認証を取得いたしました。"
				data={[
					{ key:"適合規格", val:"IATF 16949:2009"},
					{ key:"登録番号", val:"16146-T"},
					{ key:"登録事業者", val:"LSF Technology Kajang 工場"}
				]}
				isIso
			/>
		</Container>

		<Container>
			<Title>Awards</Title>

			<Column className="Column"
				img={ data["Daihatsu_regional_contribution_award"] }
				title="Daihatsu Regional Contribution Award(2014)"
			/>

			<Column className="Column"
				img={ data["Perodua_best_overall"] }
				title="Perodua Best Overall QCD Vendor (2015)"
			/>

			<Column className="Column"
				img={ data["Produa_die_skill_contest"] }
				title="Perodua Die Skill Contest（2018）"
			/>

		</Container>
		<ContactIntro />
	</PageCont>
)



export const query= graphql`
	query qualityImgs{

		Quality_hero_img: file( relativePath: { eq: "quality/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso14001: file( relativePath: { eq: "quality/iso14001.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso14001_2015: file( relativePath: { eq: "quality/iso14001-2015.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iso9001: file( relativePath: { eq: "quality/iso9001.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Iatf16146: file( relativePath: { eq: "quality/iatf16146.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Daihatsu_regional_contribution_award: file( relativePath: { eq: "quality/daihatsu-regional-contribution-award.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Perodua_best_overall: file( relativePath: { eq: "quality/perodua-best-overall.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Produa_die_skill_contest: file( relativePath: { eq: "quality/produa-die-skill-contest.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;

export default QualityPage
